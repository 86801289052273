/* WebSocketComponent.css */
.container {
    padding-top: 16px;
}

.notification {
    padding: 16px;
    margin: 8px 0;
    border-radius: 4px;
    color: #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
}

.not-resolved-bg{
    border-left: 4px solid #FA7153;
    background-color: #dadada;
}
.resolved-bg{
    border-left: 4px solid #64e062;
    background-color: #ffffff;
}
.notification p {
    margin: 0;
    align-items: end;
}

.notification .close-btn {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #721c24;
}
