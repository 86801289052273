.table-bordered td, .table thead th, .table-bordered th{
   border: 1px solid #434343;
}
@import url('https://fonts.googleapis.com/css2?family=Literata:opsz,wght@7..72,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Literata:opsz,wght@7..72,600&display=swap');

.bg-gradient-info {
    background: #1D3663 !important;
}
.btn-primary{
    background-color: #FEC349 !important;
    border: none !important;
}
.primary{
    color:#1D3663 !important;
}
.card .table td{
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.primary.btn-primary, .btn.btn-primary{
    background-color:#FEC349!important;
}
:global(.bg-gradient-info){
    background-color:#1D3663 !important;
}
.rrpClass, 
.pagination ul li.active > .page-link
,.pagination ul li.active > .page-link{
    background-color: #2d3248 !important;
}
h1,h2,h3,h4,h5,h6,.media a{
    color: #2d3248 !important;
    font-family: 'Literata', serif !important;
}
body, .footer a,#sidenav-main a{
    color: rgb(35, 33, 46) !important;
}
@import '~remixicon/fonts/remixicon.css';
body{
    font-family: 'DM Sans', sans-serif !important;
}
a:hover,#sidenav-main a:hover,.copyright a{
    color: #FEC349 !important;
}
.nav-link-icon.active{
    color: #FEC349 !important;
}
.nav-link-icon{
    color:#fff !important;
}
.p-multiselect-panel{
    z-index: 1073 !important;
}
.vertical-line {
    border-right: 1px solid #ddd; /* Add vertical line */
    padding-right: 10px; /* Adjust spacing */
  }
  
  /* Optional: Add border to the table header */
  thead th {
    border-right: 1px solid #ddd;
  }
  .table thead th {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 1px solid #000000;
}
.table-bordered th, .table-bordered td {
    border: 1px solid #000;
}
.thead-white {
    background-color: white !important;
    color: black; /* Set text color if needed */
    /* Add any other styles you need */
  }

  @media only screen and (max-width: 767px) {
    .navbar-collapse ul li a{
      color: #1D3663 !important;
    }
    .navbar-collapse ul li{
        padding: 2%;
    }
    .navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img {
        max-width: 100%;
        max-height: 5rem;
    }
    .navbar-collapse .collapse-brand img {
        height: 56px;
    }
  }

  .card .table td {
    padding-left: 1.5rem !important;
    padding-right: 5px !important;
}
.booking-details-modal{
    max-width: 900px !important;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.pulse {
    animation: pulse 2s infinite;
}

.react-calendar__month-view__days__day--weekend {
    color: initial !important;
}
.bookingClientName{
    cursor: pointer;
}
@media (min-width: 1004px) {
    .react-calendar{
        width: 900px !important;
    }
    .react-calendar__tile{
        padding: 22px !important;
    }
}

.flex-1{
    flex:1
}
